:root {
  font-smoothing: antialiased;
}

@d1: (@r * 0);
@d2: (@r * 1);
@d3: (@r * 2);
@d4: (@r * 3);
// @d5: (@r * 4);
// @d6: (@r * 5);

// @r: 18deg;
@r: 27deg;
// @n: 6;
@n: 4;

.flyout-wrap {
  position: relative;
  height: 191px;
  scale: 1.4;
  top: -38px;

  ul, li, a {
    padding: 0;
    margin: 0;
  }

  .flyout {
    position: relative;
    margin: 0 0 1px 6px;
    line-height: 0;

    & > li {
      position: absolute;
      display: block;
      height: 170px;
      background: yellow;
      transform-origin: (25px / 2) bottom;

      // Transform loop
      .generate-rotate-loop (@i) when (@i =< @n) {

        &:nth-of-type(@{i}) { transform: rotate(@r * (@i - 1)); }

        .generate-rotate-loop(@i + 1);
      }

      .generate-rotate-loop(1);

      .flyout-menu-item-box {
        cursor: pointer;
        position: absolute;
        display: block;
        width:  26px;
        height: 26px;
        overflow: hidden;
        text-indent: -99999px;
        background: #444;
        border: 3px solid #fff;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, .4) 0 0 5px 0, rgba(0, 0, 0, .2) 0 0 0 1px, inset rgba(0, 0, 0, .5) 0 0 2px 0;

        &:active {
          background: #000;
          border-color: #555;

          .flyout-menu-item {
            opacity: .3;
          }
        }

        .flyout-menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width:  26px;
          height: 26px;
          // background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxNnB4JyBoZWlnaHQ9JzE2cHgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J00xMS4wNDUsOS44NzlsMS45MDYsNi4xMTRsLTQuOTQ5LTMuNzkxTDMuMDU5LDE2bDEuOS02LjExN0wwLDYuMTE0bDYuMTIzLDAuMDEzTDcuOTk4LDBsMS44NzksNi4xMkwxNiw2LjEwNEwxMS4wNDUsOS44Nzl6Jy8+PC9zdmc+DQo=") no-repeat center center;
          background-size: 16px 16px;
          margin-left: -3px;
          margin-top: -3px;
          // mask-image: url(star.svg);
        }
      }
    }
  }

  // Triggers
  .flyout-init li {
    display: none;
  }

  // Normal
  .flyout li .flyout-menu-item-box {
    top: 160px;
    animation: contract .35s ease-out 1 backwards;
  }

  // Active
  .flyout.expand li .flyout-menu-item-box {
    top: 10px;
    animation: expand .6s ease 1 backwards;
  }

  // Clicked
  .flyout.fade li .flyout-menu-item-box.clicked {
    top: 10px;
    animation: clicked .5s ease-out 1 forwards;
  }

  .flyout.fade li .flyout-menu-item-box:not(.clicked) {
    top: 10px;  animation: fade .5s ease-out 1 forwards;

    .flyout-menu-item {
      opacity: .1;
      transition: opacity .5s ease;
    }
  }

  // Animation loop
  .generate-item-loop (@i) when (@i =< @n) {

    .flyout li:nth-of-type(@{i}) .flyout-menu-item-box                     { animation-delay: (.20s - .04s * (@i - 1)); }
    .flyout li:nth-of-type(@{i}) .flyout-menu-item-box:not(.clicked) .flyout-menu-item  { animation: ~"spin@{i}-contract .9s ease-out 1 backwards"; }
    .flyout.expand li:nth-of-type(@{i}) .flyout-menu-item-box              { animation-delay: (.04s * (@i - 1)); }
    .flyout.expand li:nth-of-type(@{i}) .flyout-menu-item-box .flyout-menu-item         { transform: rotate(-(@r * (@i - 1))); animation: ~"spin@{i}-expand .6s ease-out 1 backwards"; }
    .flyout.fade li:nth-of-type(@{i}) .flyout-menu-item-box.clicked .flyout-menu-item   { transform: rotate(-(@r * (@i - 1))); }

    .generate-item-loop(@i + 1);
  }

  .generate-item-loop(1);

  .flyout-btn {
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width:  36px;
    height: 36px;
    overflow: hidden;
    text-indent: -99999px;
    // background: #f76f54;
    background-color: #008060;
    // background: linear-gradient(top, #f76f54 0, #dd3535 49%, #d32121 51%, #c61f1f 100%);
    border: 4px solid #fff;
    border-radius: 50%;
    outline: none;
    box-shadow: rgba(0, 0, 0, .3) 0 3px 8px 0, rgba(0, 0, 0, .2) 0 0 0 1px, inset rgba(0, 0, 0, .3) 0 0 0 1px, inset rgba(255, 255, 255, .3) 0 1px 0 1px;

    &:hover {
      // never mind
    }

    span {
      display: block;
      width:  36px;
      height: 36px;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyMHB4JyBoZWlnaHQ9JzIwcHgnPjxnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J00yMCwxMS40MDJjMCwwLjQ1My0wLjM2NywwLjgyLTAuODIyLDAuODJIMC44MmMtMC40NTMsMC0wLjgyLTAuMzY3LTAuODItMC44MlY4LjU5OCBjMC0wLjQ1NCwwLjM2Ny0wLjgyMSwwLjgyLTAuODIxaDE4LjM1N0MxOS42MzMsNy43NzYsMjAsOC4xNDQsMjAsOC41OThWMTEuNDAyeicvPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J004LjU5OCwyMGMtMC40NTMsMC0wLjgyMS0wLjM2Ny0wLjgyMS0wLjgyVjAuODIxYzAtMC40NTIsMC4zNjgtMC44MiwwLjgyMS0wLjgyaDIuODA0IGMwLjQ1MywwLDAuODIsMC4zNjgsMC44MiwwLjgyVjE5LjE4YzAsMC40NTMtMC4zNjcsMC44Mi0wLjgyLDAuODJIOC41OTh6Jy8+PC9nPjwvc3ZnPg0K") no-repeat center center;
      transition: transform .4s ease;
      margin-top: -4px;
      margin-left: -4px;
    }
  }
    .flyout-btn.btn-rotate span {
      transform: rotate(-135deg);
    }

  .ani-expand {
    0%   { top: 150px; }
    50%  { top: -10px; }
    70%  { top: 15px; }
    100% { top: 10px; }
  }

  .ani-contract {
    0%   { top: 10px; }
    40%  { top: -25px; }
    100% { top: 150px; }
  }

  .ani-clicked {
    0%   { opacity: 1; transform: scale(1); }
    100% { opacity: 0; transform: scale(5); }
  }

  @keyframes expand   { .ani-expand }
  @keyframes contract { .ani-contract }

  // A small trick
  @keyframes clicked {
    0%   { transform: scale(1); opacity: 1; top: 10px; }
    90%  {                                  top: 10px; }
    99%  { transform: scale(6); opacity: 0; top: 150px; }
    100% { transform: scale(0); }
  }

  @keyframes fade {
    0%   { transform: scale(1); opacity: 1; top: 10px; }
    90%  {                      opacity: 0; top: 10px; }
    99%  { transform: scale(0);             top: 150px; }
    100% { transform: scale(0); }
  }

  // Item animation loop
  .loop-content(@v) {
    @var: "d@{v}";
    @var-keyframe-expand: ~"spin@{v}-expand";
    @var-keyframe-contract: ~"spin@{v}-contract";
  }

  .generate-slide-loop (@i) when (@i =< @n) {

    .loop-content(@i);

    @keyframes @var-keyframe-expand {
      0% { transform: rotate((0 - @@var)); }
      60% { transform: rotate((-360deg - @@var)); }
      100% { transform: rotate((-360deg - @@var)); }
    }

    @keyframes @var-keyframe-contract {
      0% { transform: rotate((0 - @@var)); }
      50% { transform: rotate(  360deg); }
      100% { transform: rotate(  360deg); }
    }

    .generate-slide-loop(@i + 1);
  }

  .generate-slide-loop(1);
}
